// Request
import { doRequest } from '@/utils/requestApi'

import Order from '../firebase/models/order'

const orderDb = new Order()

/**
 * Obtiene los pedidos comenzando en una fecha
 * y pasado el ID de un establecimiento
 *
 * @param {Date} fromDate - from date
 * @param {Date} toDate - to date
 * @param {String} placeId - UID place
 * @param {Function} cb - cb snapshot
 * @return {Array} - order array
 */
export async function getOrdersByDateAndPlaceById(fromDate, toDate, placeId, cb = null) {
  if (fromDate instanceof Date && toDate instanceof Date && placeId) {
    const orders = await orderDb.readAll({
      constraints: [
        ['placeId', '==', placeId],
        ['orderTimestamp', '>=', fromDate],
        ['orderTimestamp', '<=', toDate]
      ],
      limit: null,
      order: {
        field: 'orderTimestamp',
        direction: 'asc'
      },
      onSnapshotCb: cb
    })
    return orders
  }
  return []
}

/**
 * Obtenemos el identificador del pedido mediante su UID
 *
 * @param {string} id - UID del pedido en base de datos
 */
export async function getOrderById(id) {
  const result = await doRequest({
    method: 'get',
    url: `v1/order/${id}`
  })

  return result.data
}

/**
 * Update pedido en base de datos
 *
 * @param {Object} data - datos del pedido
 */
export async function updateOrderById(data) {
  const { id, ...params } = data
  const result = await doRequest({
    method: 'patch',
    url: `v1/order/${id}`,
    data: {
      data: params
    }
  })

  return result.data
}
