<template>
  <div class="app-notifications">
    <v-speed-dial
      v-show="toggleNotificationIcon"
      v-model="toggleNotificationMenu"
      v-bind="vSpeedDialOptions"
    >
      <!-- Botón de acción -->
      <template v-slot:activator>
        <v-btn v-model="toggleNotificationMenu" v-bind="vFabButtonOptions">
          <v-icon v-if="toggleNotificationMenu"> mdi-close </v-icon>
          <v-icon v-else class="bellshake"> mdi-bell </v-icon>
        </v-btn>
      </template>
      <!-- Contenido -->
      <v-sheet v-bind="vSheetOptions" class="app-notifications__items px-4 py-2">
        <div
          v-for="(item, i) in notificationsData"
          :key="i"
          v-ripple
          class="app-notifications__item py-2"
          v-bind="item.params"
          @click="handleClickMenuItem(i)"
        >
          <v-icon :color="item.type" small left>{{ item.icon }}</v-icon>
          <span class="text-caption">{{ item.label }}</span>
        </div>
      </v-sheet>
    </v-speed-dial>
    <!-- Sound -->
    <PlayAudio file="sound/bell" :play="playNotificationSound" />
  </div>
</template>

<script>
// Contants
import { ADDONS } from '@/constants'
// Components
import PlayAudio from '@/components/ui/PlayAudio'
// Vuex
import { mapGetters, mapMutations, mapState } from 'vuex'
// Utils
import { get, isNil } from 'lodash'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import uiMixin from '@/mixins/uiMixin'

export default {
  name: 'AppNotifications',
  components: { PlayAudio },
  mixins: [addonsMixin, uiMixin],
  data() {
    return {
      // Opciones para 'fab-btn'
      vFabButtonOptions: {
        color: 'red darken-1',
        dark: true,
        fab: true,
        small: true
      },
      // Opciones para 'v-sheet'
      vSheetOptions: {
        color: 'white',
        rounded: true,
        elevation: 3,
        width: 250
      },
      // Opciones peara 'v-speed-dial'
      vSpeedDialOptions: {
        direction: 'left',
        right: true,
        transition: 'slide-x-reverse-transition',
        bottom: true
      },
      // Others
      currentTotalItems: 0, // Número total de items en las notificaciones
      toggleNotificationSound: false, // Sonido de notificaciones
      toggleNotificationIcon: false, // Icono de notificaciones
      toggleNotificationMenu: false // Menu de notificaciones
    }
  },
  computed: {
    ...mapGetters('app', ['notificationsData']),
    ...mapState('app', ['userActivity']),
    /**
     * Opciones de TAD acerca del sonido en notificaciones
     *
     * @return {boolean}
     */
    soundNotifications() {
      return get(this.placeAddonsSetupByUser, `${ADDONS.takeAway}.soundNotifications`, false)
    },
    /**
     * Cuando exista una nueva notificación emitirá un sonido
     *
     * @return {boolean}
     */
    playNotificationSound() {
      return this.toggleNotificationSound && this.soundNotifications && this.userActivity
    }
  },
  watch: {
    /**
     * Observamos cambios en el listado de notificaciones
     */
    notificationsData: {
      handler(value) {
        // Sumatorio de los items de las notificaciones.
        // Todo esto es para evitar que la campana suene
        // cuando desciende el número de pedidos a tratar
        const totalItems =
          !isNil(value) && Array.isArray(value) && !isNil(get(value, '[0].meta', null))
            ? value.reduce((sumTotalItems, item) => {
                sumTotalItems += get(item, 'meta.totalItems', 0)
                return sumTotalItems
              }, 0)
            : 0
        // Hay notificaciones?
        const showNotification = !isNil(value) && Array.isArray(value) && value.length > 0

        // Mostramos/ocultamos icono de notificaciones
        this.toggleNotificationIcon = showNotification
        // Activamos/desactivamos el sonido de la notificación
        this.toggleNotificationSound = showNotification && totalItems > this.currentTotalItems
        // Seteamos el valor nuevo
        this.currentTotalItems = totalItems
      },
      immediate: true
    },
    /**
     * Observamos cambios en si se muestra o no el "menu"
     * (listado) de notificaciones
     */
    toggleNotificationMenu(value) {
      if (value) {
        // Cuando el menú se despliega, "apagamos" el sonido
        this.toggleNotificationSound = false
      }
    },
    /**
     * Observamos si la "interacción" del usuario con la
     * aplicación se ha llevado a cabo
     */
    userActivity: {
      handler(value) {
        // Mostramos aviso de notificaciones con sonido
        setTimeout(() => {
          if (this.soundNotifications) {
            this.modifyAppDialog({
              title: 'Notificaciones con sonido',
              contentText:
                '<p class="text-body-2">Ahora las notificaciones <b>tienen sonido</b>, por lo que, necesitamos que interactúes con la aplicación (pulsar en el botón de <b>"Entendido"</b> de este mensaje es suficiente) para que esta pueda comenzar a emitirlos.</p><p>Para silenciar "momentáneamente" las notificaciones (hasta la entrada de un proximo pedido) deberás <b>pulsar sobre el icono de notificaciones</b> (campanita en movimiento).</p><p class="text-body-2">Podrás configurar si las notificaciones emiten, o no, sonido desde tus configuraciones de <b>"Take Away/Delivery"</b>.<p class="text-body-2 error--text">Siempre que tengas activado el sonido de las notificaciones y no hayas interactuado con la aplicación, este mensaje aparecerá para advertirte de ello.</p>',
              optionsActionsButtons: {
                acceptButtonText: 'Entendido',
                acceptButtonType: 'button',
                cancelButtonHide: true
              },
              visible: !value
            })
          }
        }, 100)
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('app', ['removeAppNotification']),
    /**
     * Handle click on item
     *
     * @param {Number} index - index inside list items
     */
    handleClickMenuItem(index) {
      if (index > -1) {
        // Procedemos a la acción de la notificación,
        // por ahora solo redirigimos a una ruta de la APP
        const location = get(this.notificationsData, `${index}.location`, null)
        this.routerPushTo(location)
        // Eliminamos notificación del state
        this.removeAppNotification(index)
      }
    }
  }
}
</script>
<style lang="scss">
.app-notifications {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1;
  .v-speed-dial {
    &.v-speed-dial--direction-left,
    &.v-speed-dial--direction-right {
      // Boton
      .v-btn {
        &.v-btn--fab {
          .v-btn__content {
            .v-icon {
              // Se mueve como una campana
              &.bellshake {
                animation: bellshake 0.85s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite;
              }
            }
          }
        }
      }
      // Listado (contenido)
      .v-speed-dial__list {
        height: auto;
        bottom: 0;
        top: auto;
        .app-notifications__items {
          .app-notifications__item {
            cursor: pointer;
            border-bottom: 1px solid $theme_border_color;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }

  // Animaciones
  @keyframes bellshake {
    0% {
      transform: rotate(0);
    }
    15% {
      transform: rotate(10deg);
    }
    30% {
      transform: rotate(-10deg);
    }
    45% {
      transform: rotate(8deg);
    }
    60% {
      transform: rotate(-8deg);
    }
    75% {
      transform: rotate(6deg);
    }
    85% {
      transform: rotate(-6deg);
    }
    92% {
      transform: rotate(5deg);
    }
    100% {
      transform: rotate(0);
    }
  }
}
</style>
