export default {
  name: 'PlansGuideMetric',
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    progressLabel: {
      type: String,
      default: null
    },
    sideLabel: {
      type: String,
      default: null
    },
    size: {
      type: Number,
      default: 30
    },
    total: {
      type: Number,
      default: 100
    },
    value: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 3
    }
  }
}
