// Constants
import { PREFIX_COLLECTIONS } from '@/constants'
import { PAYMENT_TYPES, ORDER_STATUS, ORDER_TYPES } from '../../constants'
// DB
import GenericDB from '@/firebase/db/generic-db'
// Utils
import { getEnvironmentVariable } from '@/utils'

export default class Order extends GenericDB {
  // Each "defaultModel" must be defined in each Class
  defaultModel = {
    address: null,
    addressExtra: null,
    brand: getEnvironmentVariable('VUE_APP_BRAND') || 'bakarta',
    cashChange: null,
    comments: null,
    email: null,
    name: null,
    orderId: null,
    paymentType: PAYMENT_TYPES.cash.value,
    phone: null,
    placeId: null,
    products: [],
    shippingTime: 0,
    status: ORDER_STATUS.pending.value,
    type: ORDER_TYPES.takeAway.value
  }

  constructor() {
    super(`${PREFIX_COLLECTIONS.horeca}orders`)
  }

  // Here you can extend Company with custom methods
}
