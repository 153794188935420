// Constants
import { GUIDES, PACKAGES, YOUTUBE_CHANNEL } from '@/constants'
// Components
import PlansGuideMetric from '@/components/elements/plans/PlansGuideMetric'
// Mixins
import crispMixin from '@/mixins/crispMixin'
import intercomMixin from '@/mixins/intercomMixin'
// Vuex
import { mapGetters } from 'vuex'
// Utils
import { getEnvironmentVariable } from '@/utils'
import { get, isNil } from 'lodash'

export default {
  name: 'FooterNavMenu',
  components: { PlansGuideMetric },
  mixins: [crispMixin, intercomMixin],
  computed: {
    ...mapGetters('place', ['subscribedPackages']),
    /**
     * Obtenemos ID del plan actual del usuario
     *
     * @return {string}
     */
    currentPlan() {
      return get(this.subscribedPackages, '[0].packageId', PACKAGES.free.value)
    },
    /**
     * Tiene soporte de chat? ()
     *
     * @return {boolean}
     */
    hasSupportChat() {
      return !isNil(this.currentPlan) && this.currentPlan !== PACKAGES.free.value
    },
    /**
     * Es Bakarta
     */
    isBakarta() {
      return getEnvironmentVariable('VUE_APP_BRAND') !== 'schweppes'
    },
    /***
     * Enlace al canal de Youtube
     *
     * @return {string}
     */
    linkYouTube() {
      return this.isBakarta ? YOUTUBE_CHANNEL : null
    },
    /**
     * Mostramos "metrica de guía"
     *
     * @return {Boolean}
     */
    showPlansGuideMetric() {
      return this.isBakarta && this.currentPlan
    },
    /**
     * Etiqueta que acompaña al icono de la
     *
     * @return {string}
     */
    guideLabel() {
      if (isNil(this.currentPlan) || isNil(GUIDES[this.currentPlan])) return 'Sin guía'

      // Items completados por el usuario
      const guideItemsCompleted = get(this.userData, 'guideItemsCompleted', [])
      // Total items de la guía
      const totalGuideItems = GUIDES[this.currentPlan].length
      // Número total de items completados de la actual guía
      const totalCompleted = GUIDES[this.currentPlan].reduce((sumItems, item) => {
        if (guideItemsCompleted.indexOf(item.id) > -1) {
          sumItems += 1
        }
        return sumItems
      }, 0)
      // Proceso completado
      const progressCompleted = Math.round((totalCompleted * 100) / totalGuideItems)

      return progressCompleted < 100
        ? `Guía ${PACKAGES[this.currentPlan].label} (${progressCompleted}%)`
        : `Guía ${PACKAGES[this.currentPlan].label}`
    }
  },
  methods: {
    /**
     * Show the chat
     */
    handleClickSupport() {
      if (this.isBakarta && this.hasSupportChat) {
        this.intercomShow()
      } else if (!this.isBakarta) {
        this.showCrispChatIcon()
        this.openCrispChat()
      }
    }
  }
}
