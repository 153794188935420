// Contants
import { PACKAGES } from '@/constants'
// Components
import AppNotifications from '@/components/elements/store/AppNotifications'
import DefaultNavBar from '@/components/ui/DefaultNavBar'
// Vuex
import { mapGetters, mapState, mapActions } from 'vuex'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import crispMixin from '@/mixins/crispMixin'
import dataLayerMixin from '@/mixins/dataLayerMixin'
import firebaseWatchersMixin from '@/mixins/firebaseWatchersMixin'
import intercomMixin from '@/mixins/intercomMixin'
import uiMixin from '@/mixins/uiMixin'
// Utils
import { get, isNil } from 'lodash'
import { getEnvironmentVariable } from '@/utils'

export default {
  name: 'DefaultLayout',
  components: { AppNotifications, DefaultNavBar },
  mixins: [addonsMixin, crispMixin, dataLayerMixin, intercomMixin, firebaseWatchersMixin, uiMixin],
  computed: {
    ...mapGetters('place', ['placeData', 'subscribedPackages']),
    ...mapGetters('company', ['trialPlan']),
    ...mapState('app', ['selectedPlace']),
    /**
     * Obtenemos la suscripción (plan) actual del establecimiento
     *
     * @return {string}
     */
    currentSuscribedPackages() {
      return !isNil(this.subscribedPackages) && this.subscribedPackages.length > 0
        ? this.subscribedPackages[0].packageId
        : PACKAGES.free.value
    },
    /**
     * Cargamos (mostramos) el componente de notificaciones
     *
     * @return {boolean}
     */
    showAppNotifications() {
      const currentSuscribedPackages = this.currentSuscribedPackages
      return (
        currentSuscribedPackages === PACKAGES.pro.value ||
        currentSuscribedPackages === PACKAGES.advance.value
      )
    }
  },
  watch: {
    placeData: {
      async handler(newData, oldData) {
        const newPlaceId = get(newData, 'id', null)
        const oldPlaceId = get(oldData, 'id', null)

        if (!isNil(newPlaceId) && newPlaceId !== oldPlaceId) {
          // Show loading
          this.modifyAppLoadingLayer({
            visible: true
          })
          try {
            // Raciones
            await this.getEveryRations({
              placeId: newPlaceId,
              brand: getEnvironmentVariable('VUE_APP_BRAND')
            })

            if (getEnvironmentVariable('VUE_APP_BRAND') === 'bakarta') {
              const trialSuffix = this.trialPlan && this.trialPlan.daysLeft > -1 ? '_trial' : ''

              // Intercom
              await this.initializeIntercom({
                bakarta_plan: `${this.currentSuscribedPackages}${trialSuffix}`
              })
              this.intercomTrackEvent('lastLogin')

              // Datalayer
              await this.dataLayerPush({
                bakarta_place_created: get(newData, 'createTimestamp._seconds', 0),
                bakarta_plan: `${this.currentSuscribedPackages}${trialSuffix}`,
                bakarta_trial: this.trialPlan ? 'yes' : 'no',
                ...(this.trialPlan
                  ? {
                      bakarta_trial_daysLeft: this.trialPlan.daysLeft
                    }
                  : {})
              })
            } else {
              // Crisp
              this.setupCrisp()
            }
          } catch (error) {
            // Show error
            this.modifyAppAlert({
              text: error.message,
              type: 'error',
              visible: true
            })
          } finally {
            // Hide loading
            this.modifyAppLoadingLayer({
              visible: false
            })
          }
        }
      },
      immediate: true
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    ...mapActions('meta', ['getEveryRations']),
    ...mapActions('config', ['getEveryAllergens', 'getEveryLanguages']),
    /**
     * Obtiene todos los datos necesarios para la vista
     */
    async getEveryNeededData() {
      try {
        // Show loading
        this.modifyAppLoadingLayer({
          visible: true
        })
        // Alérgenos
        await this.getEveryAllergens()
        // Idiomas
        await this.getEveryLanguages()
      } catch (error) {
        // Show error
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        // Hide loading
        this.modifyAppLoadingLayer({
          visible: false
        })
      }
    }
  }
}
