// Firebase
import firebase from 'firebase/app'
// Utils
import { isNil } from 'lodash'

let asyncFirestore = null

// Cargarlo de modo asíncrono mejora el rendimiento
export default () => {
  if (isNil(asyncFirestore)) {
    asyncFirestore = import(/* webpackChunkName: "chunk-firestore" */ 'firebase/firestore').then(
      () => {
        const db = firebase.firestore()
        db.settings({
          experimentalForceLongPolling: true
          //   host: 'localhost:8081', // Emulators
          //   ssl: false // Emulators
        })
        db.enablePersistence({ synchronizeTabs: true })
        return db
      }
    )
  }
  return asyncFirestore
}
