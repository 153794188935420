// Constants
import { ADDONS, ROLES } from '@/constants'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
// Vuex
import { mapGetters } from 'vuex'
// Utils
import { get, cloneDeep } from 'lodash'
import { getEnvironmentVariable } from '@/utils'

export default {
  name: 'DefaultNavMenu',
  mixins: [addonsMixin],
  data() {
    return {
      links: [
        {
          icon: 'mdi-view-dashboard',
          label: 'Panel de control',
          name: 'dashboard',
          params: [
            {
              'data-cy': 'nav-menu-link-dashboard'
            }
          ],
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        },
        {
          icon: 'mdi-silverware',
          label: 'Cartas / Menús',
          name: 'menus',
          params: [
            {
              'data-cy': 'nav-menu-link-menus'
            }
          ],
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        },
        {
          addons: {
            [ADDONS.featuredItems]: {
              path: '/addons/featuredItems/need-upgrade'
            }
          },
          icon: 'mdi-alert-octagram',
          label: 'Destacados / Fuera de carta',
          name: 'featuredItems',
          params: [
            {
              'data-cy': 'nav-menu-link-featured-items'
            }
          ],
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        },
        {
          addons: {
            [ADDONS.takeAway]: {
              path: '/addons/takeAway/need-upgrade'
            }
          },
          childs: [
            {
              addons: {
                [ADDONS.takeAway]: {
                  path: '/addons/takeAway/need-upgrade'
                }
              },
              label: 'Gestionar',
              name: 'takeAwayOrders',
              params: [
                {
                  'data-cy': 'nav-menu-link-orders-manager'
                }
              ],
              roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
            },
            {
              addons: {
                [ADDONS.takeAway]: {
                  path: '/addons/takeAway/need-upgrade'
                }
              },
              label: 'Todos',
              name: 'takeAwayOrdersHistory',
              params: [
                {
                  'data-cy': 'nav-menu-link-orders-history'
                }
              ],
              roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
            },
            {
              addons: {
                [ADDONS.takeAway]: {
                  path: '/addons/takeAway/need-upgrade'
                }
              },
              label: 'Productos',
              name: 'takeAwayCategoryList',
              params: [
                {
                  'data-cy': 'nav-menu-link-orders-products'
                }
              ],
              roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
            }
          ],
          icon: 'mdi-tray-full',
          label: 'Pedidos',
          params: [
            {
              'data-cy': 'nav-menu-link-orders'
            }
          ],
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        },
        {
          addons: {
            [ADDONS.crm]: {
              path: '/addons/crm/need-upgrade'
            }
          },
          icon: 'mdi-account-group',
          label: 'Clientes',
          name: 'crmList',
          params: [
            {
              'data-cy': 'nav-menu-link-crm'
            }
          ],
          roles: [ROLES.horeca_admin.value]
        },
        {
          icon: 'mdi-cog',
          label: 'Configuración',
          name: 'config',
          params: [
            {
              'data-cy': 'nav-menu-link-setup'
            }
          ],
          roles: [ROLES.horeca_admin.value, ROLES.horeca_manager.value]
        }
      ],
      linksValidated: []
    }
  },
  computed: {
    ...mapGetters('place', ['placeData']),
    ...mapGetters('company', ['companyData']),
    ...mapGetters('authentication', ['userData'])
  },
  watch: {
    placeData: {
      handler(value) {
        // Cuando se modifica el establecimiento, volvemos a cargar
        // los enlaces disponibles
        if (value.id) {
          this.linksValidated = this.parseLinks(this.links)
        }
      },
      immediate: true
    },
    companyData: {
      handler(value) {
        // Cuando se modifica la compañía, volvemos a cargar
        // los enlaces disponibles
        if (value.id) {
          this.linksValidated = this.parseLinks(this.links)
        }
      },
      immediate: true
    }
  },
  methods: {
    /**
     * Se recorre de forma recursiva todos los links y los childs
     * definiendo el path y validando si se tiene los roles y
     * los addons necesarios
     */
    parseLinks(links) {
      // Proyecto actual (brand)
      const projectName = getEnvironmentVariable('VUE_APP_BRAND') || 'bakarta'
      // Se obtiene todas las rutas
      const allRoutes = get(this, '$router.options.routes[0].children', [])

      return links.reduce((sumLinks, link) => {
        // Enlace que vamos a "personalizar"
        const customLink = cloneDeep(link)
        // Limitaciones del enlace
        const linkAddOns = get(customLink, 'addons', false)
        const linkBrands = get(customLink, 'brands', false)
        const linkRoles = get(customLink, 'roles', false)
        // Condiciones del enlace
        const haveLinkBrand = !linkBrands ? true : linkBrands.indexOf(projectName) > -1
        const haveLinkRole = !linkRoles ? true : linkRoles.indexOf(this.userData.role) > -1

        // Si es visible por "brand" y por "role"
        if (haveLinkBrand && haveLinkRole) {
          // Obtenemos la ruta actual, si esta existe en "routesName"
          const currentRoute = allRoutes.find((route) => route.name === customLink.name)
          // Incluimos el path del "enrutador" al link
          if (currentRoute && currentRoute.path) {
            customLink.path = {
              name: currentRoute.name,
              params: customLink.params
            }
          }

          // ¿Poseo todos los addOns indicados del enlace?
          const haveEveryAddOns = Object.keys(linkAddOns).every((addOnId) => {
            return this.acquiredAddons.indexOf(addOnId) > -1
          })

          // Modificamos datos del enlace
          if (!haveEveryAddOns) {
            // Tomamos el primero de los addOns
            const linkOptions = linkAddOns[Object.keys(linkAddOns)[0]]
            if (linkOptions) {
              // Modificamos CSS
              customLink.css = 'no-available'
              // Modificamos PATH
              customLink.path = linkOptions.path
            }
          }

          // No se trata de un enlace (al menos no uno interno)
          if (customLink.divider || customLink.action || customLink.href) {
            sumLinks.push(customLink)
            // Es un grupo de enlaces (padre)
          } else if (customLink.childs) {
            if (haveEveryAddOns) {
              sumLinks.push(customLink)
              // Recursividad para tratar a los enlaces hijos
              customLink.childs = this.parseLinks(customLink.childs, allRoutes)
            } else {
              delete customLink.childs // eliminamos hijos
              sumLinks.push(customLink)
            }
            // Se trata de un enlace
          } else if (customLink && customLink.path) {
            sumLinks.push(customLink)
          }
        }

        return sumLinks
      }, [])
    }
  }
}
