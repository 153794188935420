// Contants
import { ADDONS } from '@/constants'
// Vuex
import { mapGetters, mapMutations } from 'vuex'
import { isNil, get } from 'lodash'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import takeAwayMixin from '@/addons/takeAway/mixins/takeAwayMixin'

export default {
  mixins: [addonsMixin, takeAwayMixin],
  data() {
    return {
      companyWatcher: null,
      placeWatcher: null
    }
  },
  computed: {
    ...mapGetters('place', ['placeData'])
  },
  watch: {
    placeData: {
      async handler(newData, oldData) {
        const newPlaceId = get(newData, 'id', null)
        const oldPlaceId = get(oldData, 'id', null)

        if (!isNil(newPlaceId) && newPlaceId !== oldPlaceId) {
          // Reset notifications
          this.resetAppNotifications()
          // Reset "takeaway" orders
          this.resetTakeAwayOrders()
          // Reset "takeaway" pre orders
          this.resetTakeAwayPreOrders()
          // Reset "hasTakeAwayMenu"
          this.setHasTakeAwayMenu(null)
          // Obtenemos pedidos actuales y pre-pedidos
          if (this.hasTakeAwayAddon) {
            // configuraciones TAD
            const takeAwayConfig = get(this.placeAddonsSetupByUser, ADDONS.takeAway, {})
            const preorderEnabled = get(takeAwayConfig, 'preorderEnabled', false)
            const preorderFutureMaxDays = get(takeAwayConfig, 'preorderFutureMaxDays', 7)
            this.getTakeAwayOrdersOnToday()
            if (preorderEnabled) {
              this.getTakeAwayPreOrders(preorderFutureMaxDays)
            }
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapMutations('app', ['resetAppNotifications']),
    ...mapMutations('takeAway', [
      'resetTakeAwayOrders',
      'resetTakeAwayPreOrders',
      'setHasTakeAwayMenu'
    ])
  }
}
