<template>
  <div class="take-away-initial-setup">
    <v-container>
      <v-sheet class="sheet" light>
        <img :src="getImage('onboarding/setting.svg')" class="sheet__image" alt="Configuración" />
        <h3 class="sheet__title">Pedidos</h3>
        <div class="sheet__content">
          <p class="grey--text">
            Antes de comenzar a usar el servicio tenemos crear la carta de "Pedidos" (esto lo
            haremos por ti), pero serás tú quién deba crear las categorías y los productos que esta
            contendrá y que ofertarás.
          </p>
          <p class="grey--text">
            Recuerda que una vez que configures tus categorías y productos, tendrás que pasarte por
            la sección de "Configuración" para indicar todas las opciones que tu servicio de
            "Pedidos" tendrá, así como su horario.
          </p>
          <p>
            <b>¿Quieres que comencemos con ello?</b>
          </p>
        </div>
        <!-- action buttons -->
        <FormButtons
          accept-button-text="Si, vamos a ello"
          :accept-button-loading="formProcessing"
          cancel-button-text="No"
          @onClickAcceptButton="handleAcceptButton"
          @onClickCancelButton="handleCancelButton"
        />
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
// Constants
import { ADDONS, MENUS_TYPES } from '@/constants'
// Components
import FormButtons from '@/components/ui/FormButtons'
// Mixins
import uiMixin from '@/mixins/uiMixin'
import addonsMixin from '@/mixins/addonsMixin'
import intercomMixin from '@/mixins/intercomMixin'
// Services
import { createCategory } from '@/services/category'
// Utils
import { getPathImage } from '@/utils'
import { get } from 'lodash'
// Vuex
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'TakeAwayInitialSetup',
  components: { FormButtons },
  mixins: [uiMixin, addonsMixin, intercomMixin],
  data() {
    return {
      formProcessing: false
    }
  },
  computed: {
    ...mapGetters('place', ['placeData'])
  },
  methods: {
    ...mapMutations('takeAway', ['setHasTakeAwayMenu']),
    /**
     * Cuando pulsamos en el botón "aceptar"
     */
    async handleAcceptButton() {
      // Loading in button
      this.formProcessing = true
      try {
        // Creamos menu
        await this.createTakeAwayMenu()
        // Establecemos en Store que ya existe menú de TAD
        this.setHasTakeAwayMenu(true)
        // Modificamos variable de Intercom
        this.intercomUpdate({ bakarta_takeAway: true })

        // Redirigimos a listado de pedidos, si es necesario
        if (this.$route.name !== 'takeAwayCategoryList') {
          this.routerPushTo({ name: 'takeAwayCategoryList' })
        }
      } catch (error) {
        // Mostramos error
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
        // nos movemos al inicio
        this.routerPushTo({ name: 'dashboard' })
      } finally {
        // Loading in button
        this.formProcessing = false
        // cerramos dialog
        this.modifyAppDialog({
          visible: false
        })
      }
    },
    /**
     * Cuando pulsamos en el botón "cancelar"
     */
    handleCancelButton() {
      // nos movemos al inicio
      this.routerPushTo({ name: 'dashboard' })
      // cerramos dialog
      this.modifyAppDialog({
        visible: false
      })
    },
    /**
     * Creamos categoría (menú TakeAway)
     */
    async createTakeAwayMenu() {
      // Configuraciones del establecimiento
      const placeConfig = get(this.placeAddonsSetupByUser, ADDONS.place, {})
      // Creamos categoría
      await createCategory(
        {
          name: 'Pedidos',
          places: [this.placeData.id],
          type: MENUS_TYPES.takeAway.value
        },
        null,
        {
          additionalLanguages: placeConfig.additionalLanguages,
          defaultLanguage: placeConfig.defaultLanguage
        }
      )
    },
    /**
     * Get path image
     *
     * @param {string} path - path to image ('public/img')
     * @param {Boolean} brand - is inside brand folder?
     * @return {string}
     */
    getImage(path, brand = false) {
      return getPathImage(path, brand)
    }
  }
}
</script>

<style lang="scss" scoped>
.take-away-initial-setup {
  height: 100%;
  width: 100%;
  .sheet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    .sheet__image {
      width: 150px;
      margin-bottom: 1rem;
    }
    .sheet__title {
      font-size: 2rem;
      line-height: 2rem;
      font-weight: normal;
      margin: 0 0 1.5rem;
      width: 100%;
      text-align: center;
    }
    .sheet__content {
      text-align: center;
      margin: 0 0 1.5rem;
      width: 100%;
    }
  }
}
</style>
