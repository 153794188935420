// Constants
import { ROLES, PACKAGES } from '@/constants'
// Components
import AppBar from '../AppBar'
import AppNavDrawer from '../AppNavDrawer'
import AppNavMenu from '../AppNavMenu'
import DefaultNavMenu from '../DefaultNavMenu'
import HeaderNavMenu from '../HeaderNavMenu'
import FooterNavMenu from '../FooterNavMenu'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
// Vuex
import { mapGetters } from 'vuex'
// Utils
import { getEnvironmentVariable } from '@/utils'

export default {
  name: 'DefaultNavBar',
  components: {
    AppBar,
    AppNavDrawer,
    AppNavMenu,
    DefaultNavMenu,
    HeaderNavMenu,
    FooterNavMenu
  },
  mixins: [addonsMixin],
  data() {
    return {
      packages: PACKAGES,
      visible: false
    }
  },
  computed: {
    ...mapGetters('authentication', ['userData']),
    ...mapGetters('place', ['subscribedPackages']),
    /**
     * Mostrar enlace a /plans
     *
     * @return {boolean}
     */
    showPlansLink() {
      return (
        getEnvironmentVariable('VUE_APP_BRAND') !== 'schweppes' &&
        this.userData.role === ROLES.horeca_admin.value &&
        this.subscribedPackages.length === 0
      )
    }
  },
  methods: {
    /**
     * Change the visibility of navDrawer
     *
     * @param {Boolean} show - show or hide navDrawer
     */
    handleVisibility(show) {
      this.visible = show
    }
  }
}
