export default {
  name: 'PlayAudio',
  props: {
    // Sin extensión del fichero, solo la ruta
    file: {
      type: String,
      default: null,
      required: true
    },
    loop: {
      type: Boolean,
      default: true
    },
    play: {
      type: Boolean,
      default: false
    },
    sources: {
      type: Array,
      default() {
        return [
          {
            format: 'mp3',
            type: 'audio/mpeg'
          },
          {
            format: 'ogg',
            type: 'audio/mpeg'
          }
        ]
      }
    }
  },
  data() {
    return {
      ref: 'playAudio'
    }
  },
  watch: {
    play(value) {
      this.handlePlayAudio(value ? 'play' : 'stop')
    }
  },
  methods: {
    /**
     * Ejecutamos (reproducir o parar) el sonido
     *
     * @param {string} action - 'play' or 'stop'
     */
    async handlePlayAudio(action = 'play') {
      const audioElement = this.$refs[this.ref]

      if (action === 'stop') {
        audioElement.pause()
        audioElement.currentTime = 0
      } else {
        await audioElement.play()
      }
    }
  }
}
