// Constans
import { ORDER_STATUS } from '../constants'
import { MENUS_TYPES } from '@/constants'
// Components
import TakeAwayInitialSetup from '../components/elements/setup/TakeAwayInitialSetup'
// Mixins
import uiMixin from '@/mixins/uiMixin'
import addonsMixin from '@/mixins/addonsMixin'
// Vuex
import { mapGetters, mapMutations } from 'vuex'
// Services
import { getOrdersByDateAndPlaceById } from '../services/order'
import { getEveryMenusByPlaceId } from '@/services/category'
// utils
import { convertFirebaseTimestampPropertiesToDate } from '@/utils'
import { isNil } from 'lodash'

export default {
  mixins: [addonsMixin, uiMixin],
  data() {
    return {
      takeAwayOrdersWatcher: null,
      takeAwayPreOrdersWatcher: null
    }
  },
  computed: {
    ...mapGetters('takeAway', ['takeAwayHasMenu']),
    ...mapGetters('place', ['placeData'])
  },
  async mounted() {
    // Obtenemos todos los datos que necesitamos
    await this.getEveryNeededData()
  },
  methods: {
    ...mapMutations('takeAway', [
      'setTakeAwayOrders',
      'setTakeAwayPreOrders',
      'setHasTakeAwayMenu'
    ]),
    ...mapMutations('app', ['addAppNotification', 'removeAppNotification']),
    /**
     * Obtenemos/establecemos todos los datos que necesita
     * el componente al inicializarse
     */
    async getEveryNeededData() {
      // Comprobamos si ya tenemos un menu TAD
      if (isNil(this.takeAwayHasMenu) || this.takeAwayHasMenu === false) {
        const result = await this.existsAnyTakeAwayMenu()
        // Establecemos valor de "takeAwayMenu"
        this.setHasTakeAwayMenu(result)
        // Modificamos variable de Intercom
        this.intercomUpdate({
          bakarta_takeAway: result
        })
      }
    },
    /**
     * Comprobamos si existe alguna carta del tipo "TakeAway"
     *
     * @return {Boolean}
     */
    async existsAnyTakeAwayMenu() {
      const menusData = await getEveryMenusByPlaceId(this.placeData.id)
      return menusData.some((menu) => {
        return menu.type === MENUS_TYPES.takeAway.value
      })
    },
    /**
     * Mostramos modal que creará el menú "takeAway" y
     * redirigirá a la pantalla de configuraciones
     */
    showDialogInitialTakeAwaySetup() {
      this.modifyAppDialog({
        title: 'Configuración',
        contentComponent: TakeAwayInitialSetup,
        hideActionButtons: true,
        visible: true
      })
    },
    /**
     * Obtenemos los pedidos del día de hoy
     */
    async getTakeAwayOrdersOnToday() {
      const now = new Date()

      // Eliminamos el watcher previo
      if (!isNil(this.takeAwayOrdersWatcher)) {
        this.takeAwayOrdersWatcher()
        this.takeAwayOrdersWatcher = null
      }

      // Obtenemos pedidos y almacenamos en el Store
      this.takeAwayOrdersWatcher = await getOrdersByDateAndPlaceById(
        new Date(now.setHours(0, 0, 0, 0)),
        new Date(now.setHours(23, 59, 59, 0)),
        this.placeData.id,
        this.snapshotTakeAwayOrdersWatcher
      )
    },
    /**
     * Obtenemos los pedidos en "pre-order"
     *
     * @param {number} maxDays - Máximo número de días para la consulta
     */
    async getTakeAwayPreOrders(maxDays = 7) {
      const today = new Date()
      const fromDate = new Date()
      const toDate = new Date()

      fromDate.setDate(today.getDate() + 1)
      toDate.setDate(today.getDate() + maxDays)

      // Eliminamos el watcher previo
      if (!isNil(this.takeAwayPreOrdersWatcher)) {
        this.takeAwayPreOrdersWatcher()
        this.takeAwayPreOrdersWatcher = null
      }

      // Obtenemos pedidos y almacenamos en el Store
      this.takeAwayPreOrdersWatcher = await getOrdersByDateAndPlaceById(
        new Date(fromDate.setHours(0, 0, 0, 0)),
        new Date(toDate.setHours(23, 59, 59, 0)),
        this.placeData.id,
        this.snapshotTakeAwayPreOrdersWatcher
      )
    },
    /**
     * Snapshot function to takeAwayOrdersWatcher
     *
     * @param {Object} querySnapshot - firebase object
     */
    snapshotTakeAwayOrdersWatcher(querySnapshot) {
      // Function to format result
      const formatResult = (result) =>
        result.docs.map((ref) => {
          return convertFirebaseTimestampPropertiesToDate({
            id: ref.id,
            ...ref.data()
          })
        })

      if (!querySnapshot.empty) {
        const data = formatResult(querySnapshot)
        // Update takeaway orders
        this.setTakeAwayOrders(data)
        // Update notifications
        const totalPendingOrders = data.reduce((sumTotal, order) => {
          if (order.status === ORDER_STATUS.pending.value) {
            sumTotal++
          }
          return sumTotal
        }, 0)

        if (totalPendingOrders > 0) {
          // Creamos / actualizamos notificación
          this.addAppNotification({
            id: 'takeAwayOrders',
            data: {
              label:
                totalPendingOrders > 1
                  ? `${totalPendingOrders} pedidos pendientes`
                  : '1 pedido pendiente',
              location: { name: 'takeAwayOrders', params: {} },
              icon: 'mdi-tray-full',
              type: 'secondary',
              meta: {
                totalItems: totalPendingOrders > 1 ? totalPendingOrders : 1
              }
            }
          })
        } else {
          // Eliminamos notificación
          this.removeAppNotification('takeAwayOrders')
        }
      }
    },
    /**
     * Snapshot function to takeAwayPreOrdersWatcher
     *
     * @param {Object} querySnapshot - firebase object
     */
    snapshotTakeAwayPreOrdersWatcher(querySnapshot) {
      // Function to format result
      const formatResult = (result) =>
        result.docs.map((ref) => {
          return convertFirebaseTimestampPropertiesToDate({
            id: ref.id,
            ...ref.data()
          })
        })

      if (!querySnapshot.empty) {
        const data = formatResult(querySnapshot)
        // Update takeaway pre-orders
        this.setTakeAwayPreOrders(data)
        // Update notifications
        const totalPendingPreOrders = data.reduce((sumTotal, order) => {
          if (order.status === ORDER_STATUS.pending.value) {
            sumTotal++
          }
          return sumTotal
        }, 0)

        if (totalPendingPreOrders > 0) {
          // Creamos / actualizamos notificación
          this.addAppNotification({
            id: 'takeAwayPreOrders',
            data: {
              label:
                totalPendingPreOrders > 1
                  ? `${totalPendingPreOrders} pedidos programados pendientes`
                  : '1 pedido programado pendiente',
              location: { name: 'takeAwayOrders', params: { preorder: 'preorder' } },
              icon: 'mdi-calendar',
              type: 'secondary',
              meta: {
                totalItems: totalPendingPreOrders > 1 ? totalPendingPreOrders : 1
              }
            }
          })
        } else {
          // Eliminamos notificación
          this.removeAppNotification('takeAwayPreOrders')
        }
      }
    }
  }
}
