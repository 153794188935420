export default {
  methods: {
    /**
     * Setup to load initial with crisp
     */
    setupCrisp() {
      // Event when close (chat)
      this.offCrispCallback('chat:closed')
      this.onCrispCallback('chat:closed', () => {
        this.hideCrispChatIcon()
      })
      // Hide icon in the beggin
      this.hideCrispChatIcon()
    },
    /**
     * Exist Crisp variable?
     *
     * @param {string} field - name field (variable) to get, format is "user:email"
     * @return {string | null}
     */
    isCrispVar(field) {
      return window.$crisp.is(field)
    },
    /**
     * Get variables from Crisp
     *
     * @param {string} field - name field (variable) to get, format is "user:email"
     * @return {string | null}
     */
    getCrispVar(field) {
      return window.$crisp.get(field)
    },
    /**
     * Set variables to Crisp
     *
     * @param {string} field - name variable, format is "user:email"
     * @param {*} value - value to save
     */
    setCrispVar(field, value) {
      return window.$crisp.push(['set', field, value])
    },
    /**
     * Do an action in Crisp
     *
     * @param {string} action - action to do
     * @param {string} options - action options
     */
    doCrispAction(action, options = null) {
      return options
        ? window.$crisp.push(['do', action, options])
        : window.$crisp.push(['do', action])
    },
    /**
     * Do a callback in Crisp
     *
     * @param {string} callBackName - callback name
     * @param {function} callBackFn - callback function
     */
    onCrispCallback(callBackName, callBackFn) {
      return window.$crisp.push(['on', callBackName, callBackFn])
    },
    /**
     * Remove a callback in Crisp
     *
     * @param {string} callBackName - callback name
     */
    offCrispCallback(callBackName) {
      return window.$crisp.push(['off', callBackName])
    },
    /**
     * Change configuration in Crisp
     *
     * @param {string} config - config to set changes
     * @param {*} value - value to set in config
     */
    changeCrispConfig(config, value) {
      return window.$crisp.push(['config', config, value])
    },
    /**
     * Hide Chat (Crisp) Icon
     */
    hideCrispChatIcon() {
      this.doCrispAction('chat:hide')
    },
    /**
     * Show Chat (Crisp) Icon
     */
    showCrispChatIcon() {
      this.doCrispAction('chat:show')
    },
    /**
     * Open Chat (Crisp)
     */
    openCrispChat() {
      this.doCrispAction('chat:open')
    }
  }
}
