import { isNil } from 'lodash'
import { convertFirebaseTimestampPropertiesToDate } from '@/utils'

import firestore from '../async-firestore'

export default class GenericDB {
  // Default values to model
  defaultModel = null

  constructor(collectionPath) {
    this.collectionPath = collectionPath
  }

  /**
   * Read all documents in the collection following constraints
   *
   * @param {Object} options - pager options
   */
  async readAll(options) {
    // Default pager options
    const defaultOptions = {
      constraints: null,
      limit: 20,
      next: null,
      order: {
        field: 'createTimestamp',
        direction: 'desc'
      },
      onSnapshotCb: null,
      prev: null,
      query: null,
      queryField: null,
      typeSearch: 'like'
    }
    // Merge default options and user options
    const currentOptions =
      options === null
        ? defaultOptions
        : {
            ...defaultOptions,
            ...(options || {})
          }
    // Function to format result
    const formatResult = (result) =>
      result.docs.map((ref) => {
        return this.convertObjectTimestampPropertiesToDate({
          id: ref.id,
          ...ref.data()
        })
      })
    // Initial ref
    const collectionRef = (await firestore()).collection(this.collectionPath)

    let refQuery = collectionRef

    if (currentOptions) {
      if (currentOptions.constraints) {
        currentOptions.constraints.forEach((constraint) => {
          refQuery = refQuery.where(...constraint)
        })
      }

      // Order by (conditions group to set it)
      if (currentOptions.order) {
        refQuery = refQuery.orderBy(currentOptions.order.field, currentOptions.order.direction)
      }

      // Search
      if (!isNil(currentOptions.query)) {
        if (!currentOptions.typeSearch || currentOptions.typeSearch === 'like') {
          // like
          refQuery = refQuery.orderBy(currentOptions.queryField, 'asc')
          refQuery = refQuery
            .startAt(currentOptions.query)
            .endAt(currentOptions.query.concat('\uf8ff'))
        } else {
          // exact
          refQuery = refQuery.where(currentOptions.queryField, '==', currentOptions.query)
        }
      } else if (currentOptions.next && currentOptions.limit) {
        // Pagination
        refQuery = refQuery
          .startAfter(currentOptions.next[currentOptions.order.field])
          .limit(currentOptions.limit)
      } else if (currentOptions.prev && currentOptions.limit) {
        // Pagination
        refQuery = refQuery
          .endBefore(currentOptions.prev[currentOptions.order.field])
          .limitToLast(currentOptions.limit)
      } else if (currentOptions.limit) {
        // Default
        refQuery = refQuery.limit(currentOptions.limit)
      }
    }

    // Snapshot
    if (typeof currentOptions.onSnapshotCb === 'function') {
      return refQuery.onSnapshot(currentOptions.onSnapshotCb)
    }

    return refQuery.get().then(formatResult)
  }

  /**
   * Convert all object Timestamp properties to date
   * @param obj
   */
  // eslint-disable-next-line class-methods-use-this
  convertObjectTimestampPropertiesToDate(obj) {
    return convertFirebaseTimestampPropertiesToDate(obj)
  }
}
